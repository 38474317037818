<template>
  <div id="autoshow2024-wrapper">
    <router-view ></router-view>
  </div>
</template>
<script>
export default {
  name: "AutoShow2023",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
#autoshow2024-wrapper {
  // padding: 1.5rem;
  margin: auto;
  background: hsla(0, 0%, 95%, 1);
  overflow: auto;
}
</style>
